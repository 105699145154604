<template>
  <body id="pdf-body" style="background: #fff">
    <div class="control-container">
      <h3 class="control-header">Contract Controls</h3>
      <section class="item-contrainer">
        <section class="control-item">
          <b-form-group
            class=""
            label="簽名位置"
            label-for="sign_position"
            label-cols-md="4"
          >
            <b-form-input
              id="sign_position"
              placeholder="簽名位置"
              v-model="contractData.information.sign_position"
            />
          </b-form-group>
          <b-button variant="primary" style="width: 100%" @click="handlePrint"
            >Print</b-button
          >
        </section>
      </section>
    </div>
    <div class="header">
      <img :src="contractLogoImage" width="auto" height="80px" />
    </div>
    <table style="width: 100%">
      <thead>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="content">
              <div class="main">
                <div class="col-xs-12">
                  <p>Ref: <span v-if="contractData.contract_id">{{contractData.contract_id}}</span></p>
                </div>

                <h4 class="center underline">{{ contractData.information.main_title }}</h4>

                <div
                  class="col-xs-12 border"
                  style="margin-top: 10px; display: flex"
                >
                  <div class="col-xs-8 border">
                    <p>{{ contractData.language.party_a_title }}：<span v-if="contractData.information.company_name">{{contractData.information.company_name}}</span></p>
                    <p>{{ contractData.language.party_a_address_title }}：<span v-if="contractData.information.br_address">{{contractData.information.br_address}}</span></p>
                  </div>
                  <div class="col-xs-4 border">
                    <p>（{{ contractData.language.br_no_text }}.: <span v-if="contractData.information.br_no">{{contractData.information.br_no}}</span>）</p>
                  </div>
                </div>
                <div class="col-xs-12 border" style="display: flex">
                  <div class="col-xs-8 border">
                    <p>
                      {{ contractData.language.party_b_title }}：
                      {{ contractData.language.party_b }}
                    </p>
                    <p>
                      {{ contractData.language.party_b_address_title }}：{{
                        contractData.language.party_b_address
                      }}
                    </p>
                  </div>
                  <div class="col-xs-4">
                    <p>
                      （{{ contractData.language.br_no_text }}.:
                      {{ contractData.language.br_no }}）
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 border ontopmargin">
                  <p>{{ replaceWords(contractData.language.contract_description) }}</p>
                </div>
                <div
                  class="col-xs-12 border ontopmargin article"
                  v-for="item in contractData.contents"
                  :key="item.id"
                >
                  <span class="title"
                    ><strong
                      >{{ item.content_sort_id }}
                      {{ item.content_title }}</strong
                    ></span
                  >
                  <div v-html="item.content_content"></div>
                </div>

                <div class="col-xs-12 border ontopmargin part12">
                  <p>
                    {{ contractData.language.agreement_description }}
                  </p>
                </div>

                <div
                  class="col-xs-12 border ontopmargin part13 article"
                  :style="{
                    display: 'flex',
                    'margin-top': contractData.information.sign_position + 'px',
                  }"
                >
                  <div class="col-xs-7">
                    <p>{{ contractData.language.party_a_title }}:</p>
                    <p></p>
                    <p>{{ contractData.language.signature_title }}:</p>
                    <div class="col-xs-12" style="min-height: 100px"></div>
                    <p>________________________________</p>
                    <p>{{ contractData.language.signature_name_title }}:<span v-if="contractData.information.contact_name">{{contractData.information.contact_name}}</span></p>
                    <p>{{ contractData.language.signature_title_title }}:<span v-if="contractData.information.title">{{contractData.information.title}}</span></p>
                    <p>
                      {{
                        contractData.language.signature_contact_number_title
                      }}: <span v-if="contractData.information.contact_name">{{contractData.information.contact_name}}</span>
                    </p>
                  </div>
                  <div class="col-xs-5">
                    <p>
                      {{ contractData.language.party_b_title }}:{{
                        contractData.language.party_b
                      }}
                    </p>
                    <p></p>
                    <p>{{ contractData.language.signature_title }}:</p>
                    <div class="col-xs-12" style="min-height: 100px">
                      <img
                        :src="contractSignImage"
                        width="auto"
                        height="100px"
                      />
                    </div>
                    <p>________________________________</p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="footer" style="width: 100%" id="footerContent">
      <div class="footer-info">
        <!--
            <div class="col-xs-12" style="text-align:center; margin-top:30px; margin-bottom:20px;">
                <p>CS@UNICONSULTS.COM&nbsp;&nbsp;&nbsp;&nbsp;WWW.UNICONSULTS.COM</p>
                <p>UNIT A , 3/F, CHEONG SUN TOWER,116-118 WING LOK STREET,SHEUNG WAN, HONG KONG</p>
            </div>
            -->
        <div class="col-xs-12"><p style="font-weight: 800">{{contractData.language.address_title}}</p></div>
        <div class="col-xs-12" style="margin-bottom: 0px; padding-bottom: 0px">
          <p style="margin: 0px; padding: 0px" v-if="contractData.language.address1">{{contractData.language.address1}}</p>
        </div>
        <div class="col-xs-12" style="display: flex">
          <div class="col-xs-9" style="margin: 0; padding: 0">
            <p style="margin: 0px; padding: 0px" v-if="contractData.language.address2">
              {{contractData.language.address2}}
            </p>
            <p style="margin: 0px; padding: 0px" v-if="contractData.language.address3">{{contractData.language.address3}}</p>
          </div>
          <div class="col-xs-3" style="margin: 0; padding: 0">
            <p style="margin: 0px; padding: 0px; font-size: 1.1em">
              {{contractData.language.contract_email}}
            </p>
            <p
              style="
                margin: 0px;
                padding: 0px;
                font-size: 1.1em;
                font-weight: 800;
              "
            >
              {{contractData.language.contract_website}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted, onmounted } from "@vue/composition-api";
import contractStoreModule from "@/views/contract/contractStoreModule";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      contractLogoImage: $themeConfig.app.contractLogoImage,
      contractSignImage: $themeConfig.app.contractSignImage,
    };
  },
  methods: {
    handlePrint() {
      console.log("here");
      window.print();
    },
    replaceWords(str){
      if(this.contractData.contract_date != null)
      {
        str = str.replaceAll('{{contract_date}}', this.contractData.contract_date)
      }
      return str;
    }
  },
  setup() {
    const contractData = ref({information:{sign_position:0}, language:{'party_a_title':''},contract_date:''});
    const invoiceTable = ref([]);

    const CONTRACT_STORE_MODULE_NAME = "contract";
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });
    if (router.currentRoute.name == "contracts-demo-preview") {
      store
        .dispatch(CONTRACT_STORE_MODULE_NAME + "/fetchDemoContract", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          contractData.value = response.data.contract;
          contractData.value.invoices.foreach(item =>{
              console.log(item)
          })

          contractData.value.information = {
            'main_title': contractData.value.template_main_title,
            'sign_position' : contractData.value.template_sign_position,
          }

          console.log("contractData", contractData.value);
        })
        .catch((error) => {});
    } else if (router.currentRoute.name == "contracts-preview") {
      store
        .dispatch(CONTRACT_STORE_MODULE_NAME + "/fetchContract", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          contractData.value = response.data.contract;
          console.log("contractData", contractData.value);
        })
        .catch((error) => {});
    }

    return {
      contractData,
    };
  },
};
</script>

<style lang="scss" >
body {
  width: 210mm;
  margin: auto;
  background: #fff;
}
html {
  background: #fff;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  margin: auto;
  text-align: center;
}
.content {
  margin-left: 0 !important;
}

* {
  font-family: "Times New Roman";
  font-size: 12px;
}
.footerheader {
  font-family: "Calibri Light", serif;
  font-size: 11.9px;
  color: rgb(190, 165, 98);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.footerp {
  font-family: "Calibri Light", serif;
  font-size: 10.1px;
  color: rgb(190, 165, 98);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

table.report-container {
  page-break-after: always;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

.title {
  display: block;
  margin: 9px 0;
}

@page: last {
  tfoot.report-footer {
    display: table-footer-group;
    position: fixed;
    bottom: 0;
  }
}
table.report-container div.article {
  page-break-inside: avoid;
}

@keyframes animate-fade-in-top {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@mixin fadeintop($duration, $delay) {
  animation-name: animate-fade-in-top;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.control-container {
  position: fixed;
  z-index: 999999;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  right: 25px;
  top: 25px;
  width: 405px;
  box-shadow: 5px 5px 10px #000000;
  opacity: 0;
  max-height: calc(100vh - 50px);
  @include fadeintop(0.3s, 0.2s);

  .control-header {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }

  .item-container {
    flex: 1 1 auto;
    overflow: auto;
    margin-bottom: 10px;

    .control-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .control {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
        width: 100%;

        .item-label {
          margin-right: 15px;
          display: block;
          padding-bottom: 3px;
          font-weight: 600;
          flex-shrink: 0;
        }

        .input-checkbox {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .input-box {
          flex: 1 1 auto;
          padding: 5px;
          border-radius: 3px;
          border: 1px solid #bbb;
          width: 0;
        }
      }

      .options-container {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;

        .options-item {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          .options-title {
            margin-right: 10px;
            font-weight: 600;
          }

          .options-value {
            color: #3574b3;
          }
        }
      }
    }
  }

  .generate-btn {
    border: 0;
    padding: 15px 25px;
    border-radius: 5px;
    color: #fff;
    background: #243b55;
    transition: 0.3s;
    text-align: center;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  .progress-container {
    width: 100%;
    margin-top: 10px;

    .progress-bar {
      width: 100%;
      height: 10px;
      border: 1px solid #585858;
      border-radius: 5px;
      margin-bottom: 5px;

      .progress {
        width: 0%;
        height: 100%;
        transition: 0.3s;
        background: #2c3e50;
      }
    }

    .generating-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
@media only screen and (max-width: 600px) {
  .control-container {
    width: 100%;
    position: unset;
  }
}

@media print {
  .control-container {
    display: none;
  }

  * {
    font-family: "Times New Roman";
    font-size: 18px !important;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  table.report-container {
    page-break-after: always;
  }

  thead.report-header {
    display: table-header-group;
  }

  tfoot.report-footer {
    display: table-footer-group;
  }
  .title {
    display: block;
    margin: 9px 0;
  }
  table.report-container div.article {
    page-break-inside: avoid;
  }
  .header {
    position: fixed;
    top: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 120px;
  }
  .footer-info p {
    color: #a6863c !important;
  }
  P {
    font-size: 12px;
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border: 1px solid #ddd;
}

.center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.border {
}

P {
  font-size: 12px;
}

.ontopmargin {
  margin-top: 20px;
}

.text-spacing {
  padding-left: 10px;
  padding-top: 5px;
}

.upperborder {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height: 30px;
}

.upperrightborder {
  border-top: 1px solid black;
  border-right: 1px solid black;
  height: 30px;
}

.footer-info p {
  color: #a6863c;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-7 {
  width: 58%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-8 {
  width: 77.7%;
}
.col-xs-5 {
  width: 42%;
}
.col-xs-4 {
  width: 33.3%;
}

.border {
  border: none !important;
}
</style>