import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDemoContract(ctx, queryParams){
        return new Promise((resolve, reject) => {
            axiosIns.get('/contract_template/demo', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchContracts(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  },
    fetchContract(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  },
    fetchTemplates(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/contract_template/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchTemplate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/contract_template`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addTemplate(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/contract_template', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/contract_template', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLanguages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/contract_template_language/list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
  },
  fetchContractTemplates(ctx, queryParams){
    return new Promise((resolve, reject) => {
        axiosIns.get('/contract_template/list', {params:queryParams})
        .then(response=>resolve(response))
        .catch(error=>reject(error))
    })
  },
  fetchContractTemplate(ctx, queryParams){
    return new Promise((resolve, reject) => {
        axiosIns.get('/contract_template', { params: queryParams , responseType: "blob" })
        .then(response=>resolve(response))
        .catch(error=>reject(error))
    })
  },
  editContractTemplate(ctx, data) {
    return new Promise((resolve, reject) => {
      axiosIns.post('/contract_template', data,)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  deleteContractTemplate(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axiosIns
        .delete("/contract_template", { params: queryParams })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  previewContract(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get("/contract_template/preview", {
          params: queryParams,
          responseType: "blob",
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  
    

  },
}
